import { useState } from "react";
import styled from "styled-components";
import Font from "../Font";
import SvgIcon, { SvgIconName } from "../SvgIcon";
import CaptionTemplate from "./CaptionTemplate";

type AccordionProps = {
  title: string;
  subText: string;
  leftIconName?: SvgIconName;
  initialOpenState?: boolean;
};

const Accordion = (props: AccordionProps) => {
  const {
    leftIconName = "infoFilled",
    subText,
    title,
    initialOpenState = false,
  } = props;

  const [isOpened, setIsOpened] = useState(initialOpenState);

  const toggleOpened = () => {
    setIsOpened((prev) => !prev);
  };

  return (
    <CaptionTemplate>
      <Container>
        <TitleContainer>
          <SvgIcon iconName={leftIconName} size={16} />
          <Font type="semibold_14" color="dark">
            {title}
          </Font>
        </TitleContainer>
        <div onClick={toggleOpened}>
          <SvgIcon
            iconName={isOpened ? "chevronUp" : "chevronDown"}
            size={16}
          />
        </div>
      </Container>
      <SubTextContainer isOpened={isOpened}>
        <Font type="regular_14" color="dark">
          {subText}
        </Font>
      </SubTextContainer>
    </CaptionTemplate>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

const SubTextContainer = styled.div<{ isOpened: boolean }>`
  display: ${({ isOpened }) => (isOpened ? "flex" : "none")};
`;

export default Accordion;
