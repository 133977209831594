const FONT_RAW_NAME_STYLE = {
  regular_10: {
    fontSize: "10px",
    lineHeight: "16p",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_11: {
    fontSize: "11px",
    lineHeight: "19px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_12: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_13: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_14: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_16: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_18: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  regular_24: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: "400",
    fontFamily: "Pretendard",
  },
  medium_12: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "500",
    fontFamily: "Pretendard",
  },
  medium_14: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "500",
    fontFamily: "Pretendard",
  },
  medium_16: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    fontFamily: "Pretendard",
  },
  semibold_10: {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_11: {
    fontSize: "11px",
    lineHeight: "20px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_12: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_13: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_14: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_16: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_18: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_20: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_22: {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_24: {
    fontSize: "24px",
    lineHeight: "34px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_26: {
    fontSize: "26px",
    lineHeight: "34px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_28: {
    fontSize: "28px",
    lineHeight: "36px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_32: {
    fontSize: "32px",
    lineHeight: "42px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  semibold_40: {
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: "600",
    fontFamily: "Pretendard",
  },
  bold_20: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "700",
    fontFamily: "Pretendard",
  },
  bold_22: {
    fontSize: "22px",
    lineHeight: "32px",
    fontWeight: "700",
    fontFamily: "Pretendard",
  },
  bold_24: {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: "700",
    fontFamily: "Pretendard",
  },
  bold_28: {
    fontSize: "28px",
    lineHeight: "39px",
    fontWeight: "700",
    fontFamily: "Pretendard",
  },
  bold_32: {
    fontSize: "32px",
    lineHeight: "42px",
    fontWeight: "700",
    fontFamily: "Pretendard",
  },
  extrabold_10: {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "900",
    fontFamily: "Pretendard",
  },
  extrabold_18: {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: "900",
    fontFamily: "Pretendard",
  },
} as const;

const FONT_NAME_STYLE = {
  display1: FONT_RAW_NAME_STYLE.bold_32,
  display2: FONT_RAW_NAME_STYLE.bold_28,
  h1: FONT_RAW_NAME_STYLE.semibold_24,
  h2: FONT_RAW_NAME_STYLE.semibold_20,
  h3: FONT_RAW_NAME_STYLE.semibold_18,
  h4: FONT_RAW_NAME_STYLE.semibold_16,
  h5: FONT_RAW_NAME_STYLE.semibold_14,
  body1: FONT_RAW_NAME_STYLE.regular_16,
  body1_medium: FONT_RAW_NAME_STYLE.medium_16,
  body2: FONT_RAW_NAME_STYLE.regular_14,
  body2_medium: FONT_RAW_NAME_STYLE.medium_14,
  body3: FONT_RAW_NAME_STYLE.regular_12,
  body3_medium: FONT_RAW_NAME_STYLE.medium_12,
} as const;

export const FONT_TYPE_STYLE = {
  ...FONT_RAW_NAME_STYLE,
  ...FONT_NAME_STYLE,
} as const;
