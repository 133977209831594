import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../Color";
import Font from "../Font";
import IconWrapper, { IconProps } from "../IconWrapper";
import { DEFAULT_PADDING } from "../constants";
import { BUTTON_COLOR_INFO } from "./Button.constants";
import { ButtonLevelType } from "./Button.types";

type LargeButtonProps = {
  level: ButtonLevelType;
  text: string;
  onPress: () => void;
  style?: CSSProperties;
  iconProps?: IconProps;
  onForcedPress?: () => void;
};

const LARGE_BUTTON_HEIGHT = 56;
const LARGE_BUTTON_DEFAULT_HORIZONTAL_PADDING = DEFAULT_PADDING;

const LargeButton = (props: LargeButtonProps) => {
  const { style, level, text, onPress, iconProps, onForcedPress } = props;

  const {
    color,
    backgroundColor = "accent",
    borderColor,
  } = BUTTON_COLOR_INFO[level];

  const handlePress = () => {
    if (onForcedPress) {
      onForcedPress();
      return;
    }

    if (level !== "disabled") {
      onPress();
    }
  };

  return (
    <Container
      style={{
        backgroundColor: Color[backgroundColor],
        borderColor: Color[borderColor || backgroundColor],
        ...style,
      }}
      onClick={handlePress}
    >
      <IconWrapper
        iconProps={iconProps}
        size={20}
        defaultPadding={LARGE_BUTTON_DEFAULT_HORIZONTAL_PADDING}
      >
        <Font
          type="medium_16"
          color={color}
          style={{
            textDecorationLine: level === "quaternary" ? "underline" : "none",
            lineHeight: "16px",
          }}
        >
          {text}
        </Font>
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 12px;
  width: 100%;
  height: ${LARGE_BUTTON_HEIGHT}px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  display: flex;
  cursor: pointer;
`;

export default LargeButton;
