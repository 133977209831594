import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import { Color } from "../Color";

type CaptionTemplateProps = {
  children: ReactNode;
  style?: CSSProperties;
};

const CaptionTemplate = (props: CaptionTemplateProps) => {
  const { children, style } = props;

  return <Container style={style}>{children}</Container>;
};

const Container = styled.div`
  border-radius: 8px;
  background-color: ${Color.light};
  padding: 16px;
  display: flex;
  width: "100%";
  flex-direction: column;
  row-gap: 10px;
`;

export default CaptionTemplate;
