import { CSSProperties } from "react";
import { IconProps } from "./IconWrapper";
import { SvgIconSize } from "./SvgIcon";

const SVG_ICON_PADDING_ADJUSTMENT: Record<SvgIconSize, number> = {
  location: 2,
  4: 2,
  12: 2,
  14: 2,
  16: 2,
  20: 4,
  24: 4,
  32: 4,
  36: 4,
  40: 4,
  48: 4,
  96: 4,
};

export const getSvgIconPaddingStyles = (
  props: Pick<IconProps, "position"> & {
    size: SvgIconSize;
    defaultPadding: number;
  }
): CSSProperties => {
  const { position, size, defaultPadding } = props;

  const paddingAdjustment = SVG_ICON_PADDING_ADJUSTMENT[size];

  return {
    paddingLeft:
      position === "leading"
        ? `${defaultPadding - paddingAdjustment}px`
        : `${defaultPadding}px`,
    paddingRight:
      position === "trailing"
        ? `${defaultPadding - paddingAdjustment}px`
        : `${defaultPadding}px`,
  };
};
