import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../Color";
import Font from "../Font";
import IconWrapper, { IconProps } from "../IconWrapper";
import { BUTTON_COLOR_INFO } from "./Button.constants";
import { ButtonLevelType, ButtonType } from "./Button.types";

export type MediumButtonProps = {
  level: ButtonLevelType;
  type: ButtonType;
  text: string;
  onPress: () => void;
  style?: CSSProperties;
  iconProps?: IconProps;
  onForcedPress?: () => void;
};

const MEDIUM_BUTTON_HEIGHT = 40;
const MEDIUM_BUTTON_DEFAULT_HORIZONTAL_PADDING = 16;

const MediumButton = (props: MediumButtonProps) => {
  const { type, level, style, text, onPress, iconProps, onForcedPress } = props;

  const {
    color,
    backgroundColor = "accent",
    borderColor,
  } = BUTTON_COLOR_INFO[level];

  const getButtonWidth = () => {
    return type === "fill" ? "100%" : "fit-content";
  };

  const handlePress = () => {
    onForcedPress && onForcedPress();

    if (level !== "disabled") {
      onPress();
    }
  };

  return (
    <Container
      style={{
        backgroundColor: Color[backgroundColor],
        borderColor: Color[borderColor || backgroundColor],
        width: getButtonWidth(),
        ...style,
      }}
      onClick={handlePress}
    >
      <IconWrapper
        iconProps={iconProps}
        size={20}
        defaultPadding={MEDIUM_BUTTON_DEFAULT_HORIZONTAL_PADDING}
      >
        <Font type="regular_14" color={color}>
          {text}
        </Font>
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  width: 100%;
  height: ${MEDIUM_BUTTON_HEIGHT}px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  display: flex;
  cursor: pointer;
`;

export default MediumButton;
