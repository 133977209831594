import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../Color";
import Font from "../Font";
import IconWrapper, { IconProps } from "../IconWrapper";
import { CHIP_COLOR_INFO, CHIP_SIZE_INFO } from "./Chip.constants";
import { ChipSize, ChipStatus } from "./Chip.types";

type ChipProps = {
  text: string;
  size: ChipSize;
  status: ChipStatus;
  onPress: () => void;
  onAbnormalPress?: () => void;
  iconProps?: IconProps;
  containerStyle?: CSSProperties;
};

const Chip = (props: ChipProps) => {
  const {
    text,
    size,
    status,
    onPress,
    containerStyle,
    iconProps,
    onAbnormalPress,
  } = props;

  const { height, padding, font } = CHIP_SIZE_INFO[size];
  const { backgroundColor, borderColor, color } = CHIP_COLOR_INFO[status];

  const handlePress = () => {
    if (status !== "disabled") {
      onPress();
    }
    if (status === "disabled") {
      onAbnormalPress && onAbnormalPress();
    }
  };

  return (
    <Container
      style={{
        height: `$${height}px`,
        backgroundColor: Color[backgroundColor],
        borderColor: Color[borderColor],
        ...containerStyle,
      }}
      onClick={handlePress}
    >
      <IconWrapper iconProps={iconProps} size={12} defaultPadding={padding}>
        <Font type={font} color={color}>
          {text}
        </Font>
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border-width: 1px;
  flex-direction: row;
  display: flex;
  width: fit-content;
`;

export default Chip;
