import { CSSProperties } from "react";
import styled from "styled-components";
import { Color } from "../Color";
import Font from "../Font";
import IconWrapper, { IconProps } from "../IconWrapper";
import { BUTTON_COLOR_INFO } from "./Button.constants";
import { ButtonLevelType } from "./Button.types";

type SmallButtonProps = {
  level: ButtonLevelType;
  text: string;
  onPress: () => void;
  style?: CSSProperties;
  iconProps?: IconProps;
  onForcedPress?: () => void;
};

const SMALL_BUTTON_HEIGHT = 32;
const SMALL_BUTTON_DEFAULT_HORIZONTAL_PADDING = 12;

const SmallButton = (props: SmallButtonProps) => {
  const { level, text, style, onPress, iconProps, onForcedPress } = props;

  const {
    color,
    backgroundColor = "accent",
    borderColor,
  } = BUTTON_COLOR_INFO[level];

  const handlePress = () => {
    onForcedPress && onForcedPress();

    if (level !== "disabled") {
      onPress();
    }
  };

  return (
    <Container
      style={{
        backgroundColor: Color[backgroundColor],
        borderColor: Color[borderColor || backgroundColor],
        ...style,
      }}
      onClick={handlePress}
    >
      <IconWrapper
        iconProps={iconProps}
        size={16}
        defaultPadding={SMALL_BUTTON_DEFAULT_HORIZONTAL_PADDING}
      >
        <Font type="regular_14" color={color}>
          {text}
        </Font>
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex-direction: row;
  justify-self: center;
  align-items: center;
  height: ${SMALL_BUTTON_HEIGHT}px;
  border-width: 1px;
  border-radius: 6px;
  width: fit-content;
  display: flex;
`;

export default SmallButton;
