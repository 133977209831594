import { memo } from "react";
import styled from "styled-components";
import Assets from "./assets";

type CheckBoxProps = {
  checked: boolean;
  onPress?: () => void;
  useBorder?: boolean;
  size?: number;
};

const CheckBox = (props: CheckBoxProps) => {
  const { size = 16, checked, onPress, useBorder = true } = props;

  const getAsset = (): keyof typeof Assets => {
    if (checked) {
      if (useBorder) {
        return "checkboxBorderedSelected";
      }
      return "checkboxSelected";
    }
    if (useBorder) {
      return "checkboxBordered";
    }
    return "checkbox";
  };

  const handlePress = () => {
    onPress && onPress();
  };

  const AssetComponent = Assets[getAsset()];

  return (
    <Container onClick={handlePress}>
      <AssetComponent width={size} height={size} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
`;

export default memo(CheckBox);
