import { CSSProperties, PropsWithChildren } from "react";
import { Color, ColorKey } from "../Color";
import { FONT_TYPE_STYLE } from "./Font.constants";
import { FontType } from "./Font.types";

type FontProps = PropsWithChildren<{
  color?: ColorKey;
  type?: FontType;
  style?: CSSProperties;
}>;

const Font = (props: FontProps) => {
  const { color = "black", type = "regular_14", children, style } = props;

  return (
    <p
      style={{
        whiteSpace: "pre-line",
        margin: 0,
        color: Color[color],
        ...FONT_TYPE_STYLE[type],
        ...style,
      }}
    >
      {children}
    </p>
  );
};

export default Font;
