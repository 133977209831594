import { CSSProperties } from "react";
import styled from "styled-components";
import { ColorKey } from "../Color";
import Font from "../Font";
import SvgIcon, { SvgIconName } from "../SvgIcon";
import CaptionTemplate from "./CaptionTemplate";

type SpreadProps = {
  subText: string | string[];
  subTextColor?: ColorKey;
  title?: string;
  titleColor?: ColorKey;
  iconName?: SvgIconName;
  containerStyle?: CSSProperties;
};

const Spread = (props: SpreadProps) => {
  const {
    subText,
    title,
    iconName = "infoFilled",
    containerStyle,
    titleColor = "dark",
    subTextColor = "dark",
  } = props;

  return (
    <CaptionTemplate style={containerStyle}>
      {title && (
        <Container>
          <SvgIcon iconName={iconName} size={16} />
          <Font type="semibold_14" color={titleColor}>
            {title}
          </Font>
        </Container>
      )}

      {typeof subText === "string" ? (
        <Font type="regular_14" color={subTextColor}>
          {subText}
        </Font>
      ) : (
        subText.map((item) => (
          <Font type="regular_14" color={subTextColor} key={item}>
            {item}
          </Font>
        ))
      )}
    </CaptionTemplate>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  width: 100%;
  align-items: center;
`;

export default Spread;
