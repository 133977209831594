import { ButtonColorInfo, ButtonLevelType } from "./Button.types";

export const BUTTON_COLOR_INFO: Record<ButtonLevelType, ButtonColorInfo> = {
  primary: {
    color: "white",
    backgroundColor: "accent",
  },
  primary_neutral: {
    color: "white",
    backgroundColor: "dark",
  },
  primary_danger: {
    color: "white",
    backgroundColor: "stateNegative",
  },
  secondary: {
    color: "accent",
    backgroundColor: "lightBlue",
  },
  tertiary: {
    color: "black",
    backgroundColor: "white",
    borderColor: "mediumLight",
  },
  disabled: {
    color: "disabledForeground",
    backgroundColor: "disabledBackground",
    borderColor: "white",
  },
  quaternary: {
    color: "black",
    backgroundColor: "white",
  },
};
