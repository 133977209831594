import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import { ColorKey } from "../Color";
import SvgIcon, { SvgIconName, SvgIconSize } from "../SvgIcon";
import { getSvgIconPaddingStyles } from "../utils";

type IconWrapperProps = {
  children: ReactNode;
  defaultPadding: number;
  size: SvgIconSize;
  iconProps?: IconProps;
};

export type IconProps = {
  position: "leading" | "trailing";
  iconName: SvgIconName;
  color?: ColorKey;
};

const IconWrapper = (props: IconWrapperProps) => {
  const { iconProps, children, size, defaultPadding } = props;

  const paddingStyles = (): CSSProperties => {
    if (iconProps) {
      return getSvgIconPaddingStyles({
        position: iconProps.position,
        size: size,
        defaultPadding,
      });
    }
    return {
      padding: `0 ${defaultPadding}px`,
    };
  };

  return (
    <div style={{ ...paddingStyles() }}>
      <Container>
        {iconProps?.position === "leading" && (
          <SvgIcon
            iconName={iconProps.iconName}
            size={size}
            color={iconProps.color}
          />
        )}
        {children}
        {iconProps?.position === "trailing" && (
          <SvgIcon
            iconName={iconProps.iconName}
            size={size}
            color={iconProps.color}
          />
        )}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
`;

export default IconWrapper;
