/* eslint-disable react/display-name */
import { createElement } from "react";
import { BottomSheet, FullScreenBottomSheet } from "../components";

const withGlobalComponents = (Component: () => JSX.Element) => {
  return (
    <>
      {createElement(Component)}
      <div style={{ position: "absolute" }}>
        <BottomSheet.Portal />
        <FullScreenBottomSheet.Portal />
      </div>
    </>
  );
};

withGlobalComponents.displayName = "withGlobalComponents";

export default withGlobalComponents;
