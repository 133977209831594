/* eslint-disable no-console */

// localStorage
export const getLocalStorageState = (key: string): string | null => {
  try {
    if (typeof window !== "undefined") {
      return JSON.parse(window?.localStorage.getItem(key) as string);
    } else {
      return null;
    }
  } catch (err) {
    return "";
  }
};

export const setLocalStorageState = <T>(key: string, value: T): void => {
  try {
    if (typeof window !== "undefined") {
      window?.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (err) {
    console.error(err);
  }
};

export const removeLocalStorageState = (key: string): void => {
  try {
    if (typeof window !== "undefined") {
      window?.localStorage.removeItem(key);
    }
  } catch (err) {
    console.error(err);
  }
};

export const removeAllLocalStorageState = (): void => {
  try {
    if (typeof window !== "undefined") {
      window?.localStorage.clear();
    }
  } catch (err) {
    console.error(err);
  }
};

// sessionStorage
export const getSessionStorageState = (key: string): string | null => {
  try {
    if (typeof window !== "undefined") {
      return JSON.parse(window?.sessionStorage.getItem(key) as string);
    } else {
      return null;
    }
  } catch (err) {
    return "";
  }
};

export const setSessionStorageState = <T>(key: string, value: T): void => {
  try {
    if (typeof window !== "undefined") {
      window?.sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (err) {
    console.error(err);
  }
};

export const removeSessionStorageState = (key: string): void => {
  try {
    if (typeof window !== "undefined") {
      window?.sessionStorage.removeItem(key);
    }
  } catch (err) {
    console.error(err);
  }
};

export const removeAllSessionStorageState = (): void => {
  try {
    if (typeof window !== "undefined") {
      window?.sessionStorage.clear();
    }
  } catch (err) {
    console.error(err);
  }
};
