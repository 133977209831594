import styled from "styled-components";
import { ColorKey } from "../Color";
import Font from "../Font";
import SvgIcon, { SvgIconName } from "../SvgIcon";
import CaptionTemplate from "./CaptionTemplate";

type BottomSheetProps = {
  title: string;
  onPress: () => void;
  iconName?: SvgIconName;
  iconColor?: ColorKey;
};

const BottomSheet = (props: BottomSheetProps) => {
  const {
    title,
    onPress,
    iconName = "infoFilled",
    iconColor = "black",
  } = props;

  return (
    <div onClick={onPress}>
      <CaptionTemplate>
        <Container>
          <TitleContainer>
            <SvgIcon iconName={iconName} size={16} color={iconColor} />
            <Font type="semibold_14" color="dark">
              {title}
            </Font>
          </TitleContainer>
          <SvgIcon iconName="chevronRight" size={16} />
        </Container>
      </CaptionTemplate>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
`;

export default BottomSheet;
