import { memo } from "react";
import styled from "styled-components";
import { Color, ColorKey } from "../Color";
import Font from "../Font";
import IconWrapper, { IconProps } from "../IconWrapper";

type LabelSizeType = "SMALL" | "LARGE";
type LabelSizeInfo = {
  paddingHorizontal: number;
  height: number;
};

export type LabelColorType =
  | "blue"
  | "grey"
  | "red"
  | "green"
  | "yellow"
  | "dim";

const LABEL_SIZE_INFOS: Record<LabelSizeType, LabelSizeInfo> = {
  SMALL: {
    paddingHorizontal: 6,
    height: 22,
  },
  LARGE: {
    paddingHorizontal: 8,
    height: 24,
  },
};

type LabelColorInfo = {
  color: ColorKey;
  backgroundColor: ColorKey;
  opacity?: number;
};

const LABEL_COLOR_INFOS: Record<LabelColorType, LabelColorInfo> = {
  blue: {
    color: "accent",
    backgroundColor: "lightBlue",
  },
  grey: {
    color: "greyScale6",
    backgroundColor: "greyScale2",
  },
  red: {
    color: "red500",
    backgroundColor: "lightRed",
  },
  green: {
    color: "green700",
    backgroundColor: "green50",
  },
  yellow: {
    color: "yellow700",
    backgroundColor: "yellow50",
  },
  dim: {
    color: "white",
    backgroundColor: "dimThick",
    opacity: 0.7,
  },
};

type LabelProps = {
  size: LabelSizeType;
  color?: LabelColorType;
  text: string;
  onPress?: () => void;
  iconProps?: IconProps;
};

const Label = (props: LabelProps) => {
  const { size, color = "blue", text, iconProps, onPress } = props;

  const { paddingHorizontal, height } = LABEL_SIZE_INFOS[size];

  const { color: _color, backgroundColor, opacity } = LABEL_COLOR_INFOS[color];

  return (
    <Container
      style={{
        height: `${height}px`,
        opacity,
        backgroundColor: Color[backgroundColor],
      }}
      onClick={onPress}
    >
      <IconWrapper
        iconProps={iconProps}
        size={12}
        defaultPadding={paddingHorizontal}
      >
        <Font type={"medium_12"} color={_color}>
          {text}
        </Font>
      </IconWrapper>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 4px;
  flex-direction: row;
  display: flex;
  width: fit-content;
  align-items: center;
`;

export default memo(Label);
