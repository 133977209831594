import { Font, SvgIcon } from "@/design-system";
import { createPortal } from "@/global-components/utils";
import { useEffect, useState, ReactNode } from "react";
import Sheet from "react-modal-sheet";
import styled from "styled-components";

type FullScreenBottomSheetProps = {
  children: ReactNode;
  isHeader?: boolean;
  headerTitle?: string;
};

const FullScreenBottomSheet = (props: FullScreenBottomSheetProps) => {
  const { children, isHeader = true, headerTitle = "" } = props;

  const [isOpen, setIsOpen] = useState(true);

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setTimeout(() => setIsOpen(false), 0)}
      detent="full-height"
      style={{ maxWidth: "560px", margin: "0 auto" }}
    >
      <Sheet.Container>
        {isHeader && (
          <Sheet.Header>
            <Header>
              <CloseButton onClick={() => setIsOpen(false)}>
                <SvgIcon size={24} iconName="close" color="black" />
              </CloseButton>
              <div>
                <Font type="semibold_18">{headerTitle}</Font>
              </div>
              <div style={{ width: "24px", height: "24px" }} />
            </Header>
          </Sheet.Header>
        )}
        <Sheet.Content>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => setTimeout(() => setIsOpen(false), 1)} />
    </Sheet>
  );
};

export default createPortal(FullScreenBottomSheet);

const Header = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;
