import Script from "next/script";

function NaverMapScript() {
  return (
    <Script
      src="https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=vjx3cs0ck2"
      strategy="beforeInteractive"
    />
  );
}

export default NaverMapScript;
