import { memo } from "react";
import styled from "styled-components";
import SvgIcon, { SvgIconSize } from "../SvgIcon";

type RadioProps = {
  selected: boolean;
  onPress?: () => void;
  size?: SvgIconSize;
  innerCircleSize?: number;
};

const Radio = (props: RadioProps) => {
  const { selected, onPress, size = 20 } = props;

  const handlePress = () => {
    onPress && onPress();
  };

  return (
    <Container onClick={handlePress}>
      <SvgIcon
        iconName={selected ? "radioButtonFilled" : "radioButton"}
        size={size}
        color={selected ? "accent" : "borderMedium"}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

export default memo(Radio);
