import accent_money from "./accent_money.svg";
import add from "./add.svg";
import addFilled from "./addFilled.svg";
import aim from "./aim.svg";
import apple from "./apple.svg";
import arrowLeft from "./arrowLeft.svg";
import baby from "./baby.svg";
import babyColor from "./babyColor.svg";
import back from "./back.svg";
import bell from "./bell.svg";
import benefit from "./benefit.svg";
import benefitFilled from "./benefitFilled.svg";
import book from "./book.svg";
import bookmark from "./bookmark.svg";
import bookmarkFilled from "./bookmarkFilled.svg";
import calendar from "./calendar.svg";
import calendarFilled from "./calendarFilled.svg";
import camera from "./camera.svg";
import cameraFilled from "./cameraFilled.svg";
import cancel from "./cancel.svg";
import cancelFilled from "./cancelFilled.svg";

import card from "./card.svg";
import cardFilled from "./cardFilled.svg";
import caution from "./caution.svg";
import cautionToast from "./cautionToast.svg";
import cautionGrey from "./caution_grey.svg";
import check from "./check.svg";
import checkCircle from "./checkCircle.svg";
import checkCircleFilled from "./checkCircleFilled.svg";
import checkCircleToast from "./checkCircleToast.svg";
import checkbox from "./checkbox.svg";
import checkboxFilled from "./checkboxFilled.svg";
import chevronDown from "./chevronDown.svg";
import chevronLeft from "./chevronLeft.svg";
import chevronRight from "./chevronRight.svg";
import chevronUp from "./chevronUp.svg";
import close from "./close.svg";
import copy from "./copy.svg";
import crown from "./crown.svg";
import delivery from "./delivery.svg";
import dialog from "./dialog.svg";
import diet from "./diet.svg";
import doctorCash from "./doctorCash.svg";
import doctorPoint from "./doctorPoint.svg";
import doctorPointWhite from "./doctorPointWhite.svg";
import dot from "./dot.svg";
import drug from "./drug.svg";
import faq from "./faq.svg";
import firstTrophy from "./firstTrophy.svg";
import free from "./free.svg";
import gardasil from "./gardasil.svg";
import giftbox from "./giftbox.svg";
import googlePlay from "./googlePlay.svg";
import greenPhone from "./greenPhone.svg";
import guide from "./guide.svg";
import hairloss from "./hairloss.svg";
import heart from "./heart.svg";
import heartFilled from "./heartFilled.svg";
import herpes from "./herpes.svg";
import history from "./history.svg";
import home from "./home.svg";
import homeFilled from "./homeFilled.svg";
import homePhoneFilled from "./homePhoneFilled.svg";
import honey from "./honey.svg";
import image from "./image.svg";
import info from "./info.svg";
import infoFilled from "./infoFilled.svg";
import injection from "./injection.svg";
import lab from "./lab.svg";
import labFilled from "./labFilled.svg";
import location from "./location.svg";
import locationFilled from "./locationFilled.svg";
import massage from "./massage.svg";
import medicalKit from "./medical_kit.svg";
import medicine from "./medicine.svg";
import medicineBottle from "./medicine_bottle.svg";
import mesotherapy from "./mesoTherapy.svg";
import modify from "./modify.svg";
import money from "./money.svg";
import more from "./more.svg";
import myPage from "./myPage.svg";
import myPageFilled from "./myPageFilled.svg";
import mydoctor from "./mydoctor.svg";
import night from "./night.svg";
import nightFilled from "./nightFilled.svg";
import oldman from "./oldman.svg";
import parcel from "./parcel.svg";
import payment from "./payment.svg";
import phone from "./phone.svg";
import phoneFilled from "./phoneFilled.svg";
import phoneFilledBig from "./phoneFilledBig.svg";
import pill from "./pill.svg";
import pillColor from "./pillColor.svg";
import pillFilled from "./pillFilled.svg";
import pregnant from "./pregnant.svg";
import price from "./price.svg";
import profileCircle from "./profileCircle.svg";
import qr from "./qr.svg";
import question from "./question.svg";
import questionFilled from "./questionFilled.svg";
import quick from "./quick.svg";
import quickFilled from "./quickFilled.svg";
import radioButton from "./radioButton.svg";
import radioButtonFilled from "./radioButtonFilled.svg";
import receipt from "./receipt.svg";
import scale from "./scale.svg";
import search from "./search.svg";
import secondTrophy from "./secondTrophy.svg";
import setting from "./setting.svg";
import shareIos from "./shareIos.svg";
import shingles_vaccine from "./shield.svg";
import skin from "./skin.svg";
import subway from "./subway.svg";
import thirdTrophy from "./thirdTrophy.svg";
import threeDot from "./threeDot.svg";
import time from "./time.svg";
import timeFilled from "./timeFilled.svg";
const IconAssets = {
  cautionGrey,
  medicalKit,
  mydoctor,
  mesotherapy,
  drug,
  medicineBottle,
  massage,
  medicine,
  shingles_vaccine,
  accent_money,
  money,
  greenPhone,
  crown,
  threeDot,
  firstTrophy,
  secondTrophy,
  thirdTrophy,
  homePhoneFilled,
  phoneFilledBig,
  add,
  dot,
  subway,
  phoneFilled,
  addFilled,
  aim,
  arrowLeft,
  baby,
  bell,
  benefit,
  benefitFilled,
  bookmark,
  bookmarkFilled,
  calendar,
  camera,
  cameraFilled,
  cancel,
  cancelFilled,
  card,
  cardFilled,
  caution,
  check,
  checkbox,
  checkboxFilled,
  checkCircle,
  checkCircleFilled,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  copy,
  delivery,
  dialog,
  giftbox,
  guide,
  heart,
  heartFilled,
  history,
  home,
  homeFilled,
  image,
  info,
  infoFilled,
  injection,
  lab,
  labFilled,
  location,
  locationFilled,
  modify,
  more,
  myPage,
  myPageFilled,
  night,
  parcel,
  payment,
  phone,
  pill,
  profileCircle,
  question,
  questionFilled,
  quick,
  radioButton,
  radioButtonFilled,
  receipt,
  scale,
  search,
  setting,
  shareIos,
  time,
  timeFilled,
  quickFilled,
  nightFilled,
  pillFilled,
  calendarFilled,
  checkCircleToast,
  cautionToast,
  back,
  close,
  price,
  doctorCash,
  doctorPoint,
  doctorPointWhite,
  free,
  pregnant,
  oldman,
  herpes,
  babyColor,
  gardasil,
  skin,
  pillColor,
  diet,
  hairloss,
  googlePlay,
  honey,
  faq,
  book,
  apple,
  qr,
};

export default IconAssets;
