/* eslint-disable @typescript-eslint/no-explicit-any */
import ModalProvider from "@/components/Modal/provider";
import MixpanelConfig from "@/constants/mixpanel/config";
import withGlobalComponents from "@/global-components/hocs";
import "@/styles/bottomSheetOverlide.css";
import colors from "@/styles/colors";
import "@/styles/globals.css";
import {
  getSessionStorageState,
  setSessionStorageState,
} from "@/utils/storageHandler";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import mixpanel from "mixpanel-browser";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NaverMapScript from "src/feature/Map/Script/NaverMapScript";
import { ThemeProvider } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { versions } from "../versions";

const theme = { colors };
declare global {
  interface Window {
    Kakao: any;
  }
}

mixpanel.init(MixpanelConfig.projectToken(), {
  debug: MixpanelConfig.debug(),
});

mixpanel.register({
  merakiWebVersion: versions[0].version,
});

const getAccessUuid = () => {
  if (!getSessionStorageState("accessUuid")) {
    const newAccessUuid = uuidv4();
    setSessionStorageState("accessUuid", newAccessUuid);

    return newAccessUuid;
  }

  return getSessionStorageState("accessUuid");
};

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

growthbook.setAttributes({ id: getAccessUuid() });
growthbook.init();

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnMount: false, cacheTime: 1000 * 60 * 60 * 24 },
        },
      })
  );

  useEffect(() => {
    growthbook.setURL(window.location.href);
    growthbook.setAttributeOverrides({
      browser: navigator.userAgent,
      url: router.pathname,
    });
  }, [router.pathname]);

  // google tag manager
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-TBND3HD" });
  }, []);

  // branch sdk
  useEffect(() => {
    async function initAndFetch() {
      const BranchSDK = (await import("branch-sdk")).default;

      const BRANCH_KEY = "key_live_ii8JmO0NSSGgOZ18z00AkogjFAn8EyNw";

      BranchSDK.init(BRANCH_KEY, {});
    }

    initAndFetch();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <GrowthBookProvider growthbook={growthbook}>
        {withGlobalComponents(() => (
          <>
            <ToastContainer
              toastStyle={{
                alignSelf: "center",
                padding: "12px 20px",
                textAlign: "center",
                fontSize: "14px",
                height: "45px",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                borderRadius: "8px",
                whiteSpace: "pre-wrap",
                top: 90,
              }}
              icon={false}
              position="top-center"
              autoClose={1500}
              hideProgressBar={true}
              closeOnClick={true}
              pauseOnHover={false}
              theme="dark"
            />
            <ModalProvider>
              <ThemeProvider theme={theme}>
                <NaverMapScript />
                <Component {...pageProps} />
              </ThemeProvider>
            </ModalProvider>
          </>
        ))}
      </GrowthBookProvider>
    </QueryClientProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default MyApp;
