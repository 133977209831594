export const versions = [
  {
    version: "3.1.13",
    description: ["feat: 건강 퀴즈 온보딩 플로우에 웹뷰 통신 로직 추가"],
  },
  {
    version: "3.1.12",
    description: ["fix: twenty48 게임 실패시 닉네임 Input 추가"],
  },
  {
    version: "3.1.11",
    description: ["feat: twenty48 게임 초대 스크린으로 라우팅"],
  },
  {
    version: "3.1.10.2",
    description: ["fix: how-to sitemap s3 timestamp format 변경 적용"],
  },
  {
    version: "3.1.10.1",
    description: ["fix: how-to sitemap 로직 변경"],
  },
  {
    version: "3.1.10",
    description: ["feat: how-to sitemap 추가"],
  },
  {
    version: "3.1.9",
    description: ["fix: 2048 게임 수정"],
  },
  {
    version: "3.1.8",
    description: ["qa: 건강비법 qa"],
  },
  {
    version: "3.1.7",
    description: ["feat: 2048 게임 구현"],
  },
  {
    version: "3.1.6",
    description: ["feat: 건강비법 믹스패널 추가"],
  },
  {
    version: "3.1.5",
    description: ["feat: 건강비법 기능 구현"],
  },
  {
    version: "3.1.4",
    description: ["fix: 믹스패널 중복 이슈 수정"],
  },
  {
    version: "3.1.3",
    description: ["feat: sitemap 클라이언트 정적으로 생성"],
  },
  {
    version: "3.1.2",
    description: ["feat: technical SEO department hub cache 적용"],
  },
  {
    version: "3.1.1",
    description: [
      "feat: technical SEO department hub 믹스패널, 사이트맵 생성",
      "fix: QA 대응",
    ],
  },
  {
    version: "3.1.0",
    description: ["feat: technical SEO department hub 페이지 및 기능 구현"],
  },
  {
    version: "3.0.11",
    description: ["chore: 숫자 맞추기 게임 점수 조정"],
  },
  {
    version: "3.0.10",
    description: ["feat: 숫자 맞추기 게임 완료"],
  },
  {
    version: "3.0.9",
    description: ["feat: 숫자 맞추기 게임 구현"],
  },
  {
    version: "3.0.8",
    description: ["fix: 병원 목록 랜딩 -> O2O랜딩하도록 변경"],
  },
  {
    version: "3.0.7",
    description: ["fix: 주관식 퀴즈 input 빈값시 버튼 미노출 처리"],
  },
  {
    version: "3.0.6",
    description: ["feat: 광고 퀴즈 풀모달 시간차 대응"],
  },
  {
    version: "3.0.5",
    description: [
      "feat: hub, hospital list, hospital detail 캐시 및 serverless Function 메모리 조정",
      "feat: image alt 추가",
      "style: hub FAQ h3 변경",
    ],
  },
  {
    version: "3.0.3",
    description: [
      "fix: 페이지네이션 오류 수정",
      "feat: Vercel Serverless Function Memory 설정",
    ],
  },
  {
    version: "3.0.2",
    description: ["feat: sitemap"],
  },
  {
    version: "3.0.1",
    description: ["feat: hospital detail SSR Vercel CDN 캐싱 설정"],
  },
  {
    version: "3.0.0",
    description: [
      "feat: Contents SEO, Technical SEO 개편",
      "feat: my-doctor.io 전용 믹스패널 작업",
    ],
  },
  {
    version: "2.10.1",
    description: ["fix: 안내문구 위치 조정"],
  },
  {
    version: "2.10.0",
    description: ["feat: 크레커 테스트"],
  },
  {
    version: "2.9.5",
    description: ["feat: 건강퀴즈 풀었을 때 5회연속 넛지 ABTest"],
  },
  {
    version: "2.9.4",
    description: ["feat: 홈에서 퀴즈를 풀 수 있는 기능 추가"],
  },
  {
    version: "2.9.3",
    description: [
      "fix: 의사 리뷰 호출시 생기는 에러 핸들링",
      "fix: 이수연 의사 의사 상세 노출 제거",
    ],
  },
  {
    version: "2.9.2",
    description: ["feat: web mixpanel 업데이트 및 trigger 추가"],
  },
  {
    version: "2.9.1",
    description: [
      "fix: post message로 믹스패널 업데이트하는 쪽 이벤트 명 변경",
    ],
  },
  {
    version: "2.9.0",
    description: [
      "feat: bottomContents default 추가 및 디자인 작업",
      "feat: mixpanel project 나만의닥터 앱과 같은 project 바라보도록 변경",
      "remove: 불필요한 라이브러리 제거",
    ],
  },
  {
    version: "2.8.0",
    description: [
      "feat: my-doctor.io 사용성 개선 작업",
      "feat: footer 하단 주소 및 고객센터 운영시간 추가",
      "feat: 불필요한 파일들 제거 작업",
    ],
  },
  {
    version: "2.7.0",
    description: ["feat: SEO용 서비스 소개 페이지"],
  },
  {
    version: "2.6.0",
    description: ["feat: bottomContents 디자인 버그 수정"],
  },
  {
    version: "2.5.3",
    description: [
      "feat: 퀴즈 디자인 및 사용성 변경",
      "feat: 광고 문의 메일 주소 변경",
      "feat: 퀴즈 description 변경",
    ],
  },
  {
    version: "2.5.2",
    description: ["feat: 6월 퀴즈 2배 이벤트 대응"],
  },
  {
    version: "2.5.1",
    description: [
      "fix: 건강 퀴즈 요청 값 변경",
      "fix: 건강 퀴즈 버튼 텍스트 변경",
    ],
  },
  {
    version: "2.5.0",
    description: [
      "feat: 이벤트 페이지 cms 이관",
      "feat: 건강꿀팁 무한스크롤 적용",
    ],
  },
  {
    version: "2.4.1",
    description: ["feat: 닥터포인트 V2"],
  },
  {
    version: "2.4.0",
    description: ["feat: 건강 퀴즈 V2"],
  },
  {
    version: "2.3.0",
    description: [
      "feat: 믹스패널 누락된 이벤트 추가",
      "feat: 앱에서 건강꿀팁 상단 여백이 생기는 버그 해결",
      "feat: 컴포넌트 하단 CTA 컴포넌트 추가",
    ],
  },
  {
    version: "2.2.1",
    description: ["feat: 5월 앱리뷰 이벤트 추가"],
  },
  {
    version: "2.2.0",
    description: ["feat: 질환백과 추가"],
  },
  {
    version: "2.1.1",
    description: ["feat: 핑크다이어리 앱리뷰 이벤트"],
  },
  {
    version: "2.1.0",
    description: [
      "feat: 건강 FAQ",
      "feat: 헤더에 불필요한 코드 제거",
      "feat: paginator 스타일 변경",
      "feat: 건강 꿀팁 디자인 수정",
    ],
  },
  {
    version: "2.0.0",
    description: [
      "feat: 의사 리스트 SEO 작업",
      "feat: 의사 상세 페이지 인터널 링크 SEO 작업",
    ],
  },
];
