import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import Modal from "..";

export type ModalContextType = {
  isOpen: boolean;
  openModal: (modalContents: ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextType | null>(null);

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalContents, setModalContents] = useState<ReactNode | null>(null);

  const isOpen = Boolean(modalContents);

  const openModal = (contents: ReactNode) => {
    setModalContents(contents);
  };

  const closeModal = (closeCallback?: () => void) => {
    closeCallback && closeCallback();
    setModalContents(null);
    return;
  };

  const value = {
    isOpen,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={value}>
      {children}
      {isOpen && <Modal closeModal={closeModal}>{modalContents}</Modal>}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModal = () => {
  const value = useContext(ModalContext);

  if (value === null) {
    throw new Error("Modal 에러");
  }

  return value;
};
