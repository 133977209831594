import styled from "styled-components";
import { HeadingProps } from "./types";
import { FONT_TYPE_STYLE } from "../Font/Font.constants";
import { Color } from "@/design-system";

export const H1 = styled.h1<HeadingProps>`
  margin: 0;
  padding: 0;
  ${({ fontType = "h1", textAlign = "left", color = "black" }) => ({
    ...FONT_TYPE_STYLE[fontType],
    textAlign,
    color: Color[color],
    whiteSpace: "pre-line",
  })}
`;

export const H2 = styled.h2<HeadingProps>`
  margin: 0;
  padding: 0;
  ${({ fontType = "h2", textAlign = "left", color = "black" }) => ({
    ...FONT_TYPE_STYLE[fontType],
    textAlign,
    color: Color[color],
    whiteSpace: "pre-line",
  })}
`;

export const H3 = styled.h3<HeadingProps>`
  margin: 0;
  padding: 0;
  ${({ fontType = "h3", textAlign = "left", color = "black" }) => ({
    ...FONT_TYPE_STYLE[fontType],
    textAlign,
    color: Color[color],
    whiteSpace: "pre-line",
  })}
`;

export const H4 = styled.h4<HeadingProps>`
  margin: 0;
  padding: 0;
  ${({ fontType = "h4", textAlign = "left", color = "black" }) => ({
    ...FONT_TYPE_STYLE[fontType],
    textAlign,
    color: Color[color],
    whiteSpace: "pre-line",
  })}
`;
